import React from "react";

function Loader() {
  return (
    <div className="loader">
      <img className="breathing" src="/logo/1.jpeg" alt="Zelso" />
    </div>
  );
}

export default Loader;
